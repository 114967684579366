.navbar {
  display: none; }

#gettingStartedPopover { z-index: 1;}

#nav-title {
  font-size: 25px;
  font-weight: 100;
  letter-spacing: 5px;
}

/* Larger than tablet */
@media (min-width: 750px) {
  /* Navbar */
  .navbar + .docs-section {
    border-top-width: 0; }
  .navbar,
  .navbar-spacer {
    display: block;
    width: 100%;
    height: 6.5rem;
    background: #000;
    z-index: 99;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee; }
  .navbar-spacer {
    display: none; }
  .navbar > .container {
    width: 100%; }
  .navbar-list {
    list-style: none;
    margin-bottom: 0; }
  .navbar-item {
    position: relative;
    float: left;
    margin-bottom: 0; }
  .navbar-link {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .2rem;
    margin-right: 35px;
    text-decoration: none;
    line-height: 6.5rem;
    color: #fff; }
  .navbar-link.active {
    color: #33C3F0; }
  .has-docked-nav .navbar {
    position: fixed;
    top: 0;
    left: 0; }
  .has-docked-nav .navbar-spacer {
    display: block; }
  /* Re-overiding the width 100% declaration to match size of % based container */
  .has-docked-nav .navbar > .container {
    width: 80%; }

  /* Popover */
  .popover.open {
    display: block;
  }
  .popover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    top: 82%;
    left: -10.5%;
    -webkit-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
       -moz-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
            filter: drop-shadow(0 0 6px rgba(0,0,0,.1)); }
  .popover-item:first-child .popover-link:after,
  .popover-item:first-child .popover-link:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .popover-item:first-child .popover-link:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px; }
  .popover-item:first-child .popover-link:before {
    border-color: rgba(238, 238, 238, 0);
    border-bottom-color: #eee;
    border-width: 11px;
    margin-left: -11px; }
  .popover-list {
    padding: 0;
    margin: 0;
    list-style: none; }
  .popover-item {
    padding: 0;
    margin: 0; }
  .popover-link {
    position: relative;
    color: #222;
    display: block;
    padding: 8px 20px;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.0rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: .1rem; }
  .popover-item:first-child .popover-link {
    border-radius: 4px 4px 0 0; }
  .popover-item:last-child .popover-link {
    border-radius: 0 0 4px 4px;
    border-bottom-width: 0; }
  .popover-link:hover {
    color: #fff;
    background: #33C3F0; }
  .popover-link:hover,
  .popover-item:first-child .popover-link:hover:after {
    border-bottom-color: #33C3F0; }
}
